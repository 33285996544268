.register{
    padding: 25vh 25vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.form__field{
    margin: 2vh 0vw;
}

.form__field:last-child{
    border-top: 1px solid var(--green);
    border-bottom: 1px solid var(--green);
    padding: 2vh 0;
}

.form{
 text-align: center;
}

.submit_auth_form{
    background-color: var(--green);
    border-radius: 5px;
    padding: 5px;
    color: white;
    border: none;
    font-family: var(--font1);
    width: 50%;
    letter-spacing: 2px;
    font-weight: 600;
}

.submit_auth_form:hover{
    background-color: var(--lighter-green);
    cursor: pointer;
}

.auth_input{
    background-color: var(--green);
    border: none;
    height: 22px;
    color: white;
}