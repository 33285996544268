@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro');

.selectedXp_body{
    position: relative;
    height: 50vh;
    margin: 0;
    padding: 0;
    z-index: 900;
    margin-left: 2vw;
    margin-right: 2vw;
    background-color: var(--grey);
    animation: fadeInDown 1.2s;
    overflow: hidden;
    padding-bottom: 5vh;
    padding-top: 2.5vh;
    border-bottom: 1px solid rgb(80, 80, 80);
    transition: ease-out 1s;
}

#sXP_title{
    position: relative;
    margin: 0;
    color: var(--white);
    text-align: center;
    font-size: 2.2vw;
    animation: fadeIn 3s;
    border-bottom: 2px solid var(--ligther-grey);
    margin: 0vw 10vw 0vw 10vw;
}

.row{
    position: relative;
    height: 85%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    animation: fadeIn 3s;
}

.column{
    position: relative;
    width: 30vw;
    height: 36vh;
    animation: fadeIn 3s;
    word-wrap: break-word;
    user-select: none;
}

.column_centered{
    text-align: center;
    list-style-type: none;
}

#ul_principles{
    margin-top: 5vh;
    
}

.js_title{
    position: relative;
    left: 1vw;
    user-select: none;

    color: var(--white);
}

.js_title_centered{
    text-align: center;
    left: 0vw;
}

.js_li{
    position: relative;
    top: -1vh;
    color: var(--white);  
    font-size: 1vw;  
    margin-bottom: 3vh;
    margin-top: 3vh;
    user-select: none;
    list-style-type: circle;
}

.js_li_centered{
    position: relative;
    left: 0vw;
    list-style-type: none;
}

.project_note{
    position: relative;
    top: -2vh;
    margin-bottom: 6vh;
    color: var(--white);
    word-spacing: 4px;
    margin: 0;
    user-select: none;
    color: #ffa500;
}

.project_img:hover{
    opacity: 0.8;
}

.project_img{
    position: relative;
    object-fit: contain;
    width: 85%;
    max-height: 30vh;
    cursor: pointer;
    border-radius: 5px;

}

.project_img_nolink{
    cursor: auto;
    position: relative;
    object-fit: contain;
    width: 85%;
    height: 25vh;
}


.__css_img{
    width: 20%;
    margin-left: .5%;
    margin-right: .5%;
}

#amish_img{
    opacity: 0.3;
    object-fit: scale-down;
    cursor: auto;
}

#in_dev_p{
    position: absolute;
    text-align: center;
    width: 100%;
    color: orange;
    font-weight: 600;
    margin-top: 6vh;
    user-select: none;
    z-index: 1000;
}



#iphone_icon{
    display: block;
    position: relative;
    object-fit: contain;
    margin: 0 auto;
    margin-top: -1.5vh;
    margin-bottom: 1vh;
    width: 2vw;
}

.js_note{
    position: relative;
    width: 95%;
    height: 95%;
    color: var(--white);
    word-spacing: 6px;
    margin: 0;
    font-size: 0.9vw;
    margin-top: 0vh;
    left: 1.2vw;
}

#div_frameworks{
    position: relative;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    height: 80%;

}

.code_no_ani{
    position: relative;
    color: #ffa500;
    top: 0.3vw;
    left: 1vw;
    font-size: 0.8vw;
    overflow: hidden;
    white-space: nowrap; 
    margin: 0 auto;
    letter-spacing: .10em;
    display: inline-block;
}

.__frameworks{
    width: auto;
    height: 3vh;
    margin: 0;
    font-size: 1vw;
    font-weight: 800;
    margin-left: .5vw;
    margin-right: .5vw;
}

.code{
    position: relative;
    color: orange;
    top: .6vh;
    left: .5vw;
    font-size: 0.8vw;
    overflow: hidden;
    border-right: .15em solid orange; 
    white-space: nowrap; 
    margin: 0 auto;
    letter-spacing: .10em;
    display: inline-block;

    animation: 
      typing 8.5s steps(40, end) infinite,
      blink-caret .75s step-end infinite;
  }

  .code2{
    animation: 
    typing2 7.5s steps(40, end) infinite,
    blink-caret .75s step-end infinite;
  }

  .code3{
    animation: 
    typing3 7.0s steps(40, end) infinite,
    blink-caret .75s step-end infinite;
  }

  .code3_1{
    animation: 
    typing3_1 7.0s steps(40, end) infinite,
    blink-caret .75s step-end infinite;
  }

  .code3_2{
    animation: 
    typing3_2 7.0s steps(40, end) infinite,
    blink-caret .75s step-end infinite;
  }

  .code4{
    animation: 
    typing4 7.3s steps(40, end) infinite,
    blink-caret .75s step-end infinite;
  }

  .code4_1{
    animation: 
    typing4_1 7.3s steps(40, end) infinite,
    blink-caret .75s step-end infinite;
  }
  
  /* text animation */
  
@keyframes typing {
    from { width: 0 }
    to { width: 65% }
}

@keyframes typing2 {
    from { width: 0 }
    to { width: 60% }
}

@keyframes typing3 {
    from { width: 0 }
    to { width: 45% }
}

@keyframes typing3_2 {
    from { width: 0 }
    to { width: 40% }
}


@keyframes typing3_1 {
    from { width: 0 }
    to { width: 55% }
}


@keyframes typing4 {
    from { width: 0 }
    to { width: 75% }
}


@keyframes typing4_1 {
    from { width: 0 }
    to { width: 80% }
}

  
/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #ffa500; }
}



@media only screen and (max-width: 900px){

    .selectedXp_body{
        height: auto;
        position: relative;
        margin: 0;
        padding: 0;
    }

    #sXP_title{
        font-size: 6vw;
    }

    .js_title{
        font-size: 3.5vw;
    }

    .row{
        display: flex;
        flex-direction: column;
    }
    
    .column{
        width: 80vw;
        height: 15%;
        margin: 0 auto;
        margin-top: -0.5vh;
        border-bottom: 2px solid var(--ligther-grey);
        padding-bottom: 2vh;
    }

    #ul_principles{
        margin-top: 0;
    }

    .code{
        top: 0.7vw;
        left: .5vw;
        font-size: 2vw;
        margin: 0 auto;
        margin-top: 1.5vh;
        display: inline-block;
        letter-spacing: 2px;
      }

      .code_no_ani{
        font-size: 2.5vw;
        padding-right: 5vw ;
        top: 0.8vh;
        }
    

      
    .js_li{
        position: relative;
        top: -1vh;
        color: var(--white);  
        font-size: 2.2vw;  
        margin-bottom: 1vh;
        margin-top: 1vh;
    }

    .js_note{
        position: relative;
        margin-top: -10vh;
        text-align: center;
        width: 80%;
        font-size: 2vw;
        margin: 0 auto;
    }

    .project_img{
        margin-top: -2vh;
    }

    .project_note{
        font-size: 3vw;
    }

    #iphone_icon{
        width: 5vw;
        height: 5vw;
        margin-bottom: 3vh;
    }

}


@media only screen and (max-width: 500px){

    .selectedXp_body{
        height: auto;
        position: relative;
        margin: 0;
        padding: 0;
    }

    #sXP_title{
        font-size: 6vw;
    }

    .js_title{
        font-size: 4.7vw;
    }

    .row{
        display: flex;
        flex-direction: column;
    }
    
    .column{
        width: 95vw;
        height: 15%;
        margin: 0 auto;
        margin-top: -0.5vh;
        border-bottom: 2px solid var(--ligther-grey);
        padding-bottom: 2vh;
    }

    #ul_principles{
        margin-top: 0;
    }

    .code{
        top: 1vw;
        left: .5vw;
        font-size: 2.7vw;
        margin: 0 auto;
        margin-top: 1.5vh;
        display: inline-block;
        letter-spacing: 2px;
      }

    .code_no_ani{
        font-size: 2.5vw;
        top: 0.4vh;
    }

      
    .js_li{
        position: relative;
        top: -1vh;
        color: var(--white);  
        font-size: 3vw;  
        margin-bottom: 1vh;
        margin-top: 1vh;
    }

    .js_note{
        position: relative;
        margin-top: -10vh;
        text-align: center;
        width: 90%;
        font-size: 3.2vw;
        margin: 0 auto;
    }

    .project_img{
        margin-top: -2vh;
    }

    .project_note{
        font-size: 3vw;
    }

    #iphone_icon{
        width: 6vw;
        height: 6vw;
        margin-bottom: 3vh;
    }


}



.coming_soon{
    display: flex;
    align-items: center;
    justify-content: center;
}