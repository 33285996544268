#header-body{
    position: relative;
    font-size: 1.5vw;
    padding: 5px;
    letter-spacing: 2px;
    user-select: none;
    height: 9vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.header-content{
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: right;
    width: 90%;
}

.line{
    position: absolute;
    border: 1px solid white;
    background-color: white; 
    bottom: -1px;
    margin: 0 auto;
}

.no_deco{
    text-decoration: none;
    color: white;
}

.no_deco:hover{
    color: rgba(240, 248, 255, 0.548);
}

.login_link{
    border: none;
    border-radius: 10px;
    padding-inline: 0.6vw;
    width: auto;
    margin: 0 2vw;
    height: auto;
    text-align: center;
    font-size: 1.2rem;
    background-color: transparent;
    cursor: pointer;
    font-family: var(--font1);
}

.login_link:hover{
    color:  rgba(240, 248, 255, 0.548);
}

.profileDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border: none;
    user-select: none;
    font-size: 22px;
}

.profile_img{
    width: 50px;
    height: 50px;
    margin: 0;
}

.username{
    margin: 0px;
}


.line{
    width: 90vw;
}

.menu{
    position: absolute;
    left: 10vw;
}