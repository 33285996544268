#loc_body {
    position: relative;
    height: 80vh;
    margin: 2vw;
    margin-top: 10vh;
    margin-bottom: 10vh;
    background: var(--grey);
    z-index: 900;
    border-bottom: 1px solid rgb(80, 80, 80);
    border-top: 1px solid rgb(80, 80, 80);
}

#loc_title {
    position: relative;
    font-size: 3rem;
    text-align: center;
    margin: 0;
    color: var(--white);
    user-select: none;
    text-shadow: 8px 2px var(--grey);
}

#loc_row {
    margin: 0 auto;
    padding: .5rem 0rem .5rem .5rem;
    height: 75%;
    width: 98%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.loc_column {
    position: relative;
    width: 49%;
    height: 100%;
}

.__col2 {
    width: 100%;
    margin: 0;
}

.col_title {
    padding-left: 2.5vw;
    color: orange;
    font-size: 1.5rem;
    margin-top: 0;
    display: inline-flex;
    width: 100%;
}

#flags {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1vw;
}

.flag {
    margin: 0;
    width: 2.5vw;
    height: 4vh;
    object-fit: scale-down;
}

#my_form {
    display: flex;
    flex-direction: column;
    padding: 0 2.5vw 0 2.5vw;
}

.form_p {
    color: var(--white);
    margin: 0;
    font-family: var(--font1);

}

._in {
    min-height: 5vh;
    background-color: var(--ligther-lighter-grey);
    color: var(--white);
    font-size: 1rem;
    margin: 0 0 2vh 0;
    font-family: var(--font1);
    letter-spacing: 1px;
    border: none;
}


#in_text {
    height: 18vh;
    width: 40vw;
    max-height: 18vh;
    max-width: 40vw;
    min-width: 20vw;
    min-height: 10vh;
    overflow: scroll;
    overflow-x: hidden;
    /* Hide horizontal scrollbar */
}

#form_btn {
    background-color: orange;
    height: 6vh;
    width: 8vw;
    margin: 0 auto;
    font-family: var(--font1);
    font-size: 1.1rem;
    font-weight: 400;
    cursor: pointer;
}

#map {
    position: relative;
    object-fit: cover;
    height: 95%;
    width: 100%;
}

#zoom_div {
    margin: 0;
}

.zoom_btn {
    position: relative;
    margin: 0;
    margin-top: 1vh;
    margin-right: 1vw;
    top: -1vh;
    border: none;
    font-family: var(--font1);
    height: 100%;
    color: var(--black);
    background-color: orange;
    font-weight: 600;
    cursor: pointer;
}

.zoom_btn:hover {
    transform: scale(1.05);
    transition: ease .5s;
}



textarea::-webkit-scrollbar {
    width: 1em;
    background-color: transparent;

}

textarea::-webkit-scrollbar-thumb {
    background-color: var(--blue);
}

textarea::-webkit-resizer {
    background-color: var(--blue);
}


@media only screen and (max-width: 900px) {

    #loc_body {
        height: 100%;
    }

    #loc_row {
        flex-direction: column-reverse;
    }

    .loc_column {
        width: 100%;
    }

    .__col2 {
        position: relative;
        margin-bottom: 2vh;
    }

    #map {
        object-fit: cover;
        height: 35vh;
        width: 100%;
    }

    .flag {
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }

    #in_text {
        width: 100vw;
        max-width: 100%;
        font-size: 3vw;
    }

    #form_btn {
        width: 25vw;
        max-width: 100%;
        margin-bottom: 2vh;
    }

    #loc_title {
        font-size: 10vw;
    }
}

@media only screen and (max-width: 900px) {

}