.App {
  height: 100%;
  margin: 0;
  padding: 0;
  display: block;
  overflow: hidden;
  color: white;
}

.popup_modal{
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.6);
  overflow: auto;
  font-family: var(--font1);
}

.popup_div{
  position: fixed;
  color: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--green);
  border-radius: 25px;
  width: 50vw;
  height: 25vh;
}

.popup_img{
  width: 50px;
  height: 50px;
  user-select: none;
}

.popup_p{
  font-family: inherit;
  font-size: 20px;
}

.popup_btn{
  background-color: #5f6a79;
  border-radius: 25px;
  height: 30px;
  width: 80px;
  border: none;
  cursor: pointer;
  font-family: var(--font1);
}

.popup_btn:hover{
  background-color: #a3a8af;
}