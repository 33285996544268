#body_100 {
    position: relative;
    height: 100%;
    z-index: 1000;
    background-color: transparent;
}

#xp_body {
    position: relative;
    max-height: 50vh;
    margin: 2vw;
    margin-bottom: 0;
    z-index: 1000;
    background-color: var(--grey);
    overflow: hidden;
    border-top: 1px solid rgb(80, 80, 80);
    border-bottom: 1px solid rgb(80, 80, 80);
    padding-bottom: 5vh;
    padding-top: 2.5vh;
}



#title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 2vh;
    color: var(--white);
    text-shadow: 8px 2px var(--grey);
    user-select: none;
    margin-top: 0;
}


#xp_graph {
    position: relative;
    display: block;
    color: white;
    left: 50vw;
    width: 45%;
    top: 1vh;
    height: 37vh;
    margin: 0;
}




.chart_label_img {
    position: relative;
    top: 2vh;
    width: 2.5vw;
    height: 2.5vw;
}

#container {
    position: relative;
    top: -35vh;
    display: flex;
    justify-content: center;
    width: 45%;
    flex-wrap: wrap;
    z-index: 1000;
    margin-top: 2.5vh;
}

#container_title {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -5.5vh;
    font-size: 1vw;
    color: var(--white);
    margin: 1vh 0vw;
}

.item {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    width: 17%;
    border: 1px solid var(--ligther-grey);
    z-index: 1000;
    cursor: pointer;
    padding: 1vh 0 1vh 0;
}

.item:hover{
    background-color: var(--ligther-grey);
}



.item_img {
    position: relative;
    top: 0.2vh;
    width: 4vw;
    padding: 0;
    margin: 0;
    z-index: 1000;
    object-fit: contain;
    user-select: none;
    pointer-events: none;
}

.item_img_small {
    position: relative;
    top: 0.2vh;
    width: 3vw;
    padding: 0;
    margin: 0;
    z-index: 1000;
    object-fit: contain;
    user-select: none;
    pointer-events: none;
}

.item_txt {
    display: block;
    position: relative;
    font-size: .8rem;
    color: white;
    margin: 0;
    z-index: 1000;
    letter-spacing: .1rem;
    user-select: none;
    pointer-events: none;
}


@media only screen and (max-width: 900px) {


    #xp_body {
        display: flex;
        flex-direction: column;
        max-height: max-content;
        margin: 0;
        overflow: visible;
        padding-bottom: 2.5vh;
    }

    #xp_graph {
        left: 0vw;
        width: 90%;
        top: 2vh;
        height: 30vh;
        margin: 0 auto;
        margin-bottom: 10vh;
    }

    .chart_label_img {
        width: 5vw;
        height: 5vw;
    }

    #container {
        top: 0vh;
        margin: 0 auto;
        margin-top: 5vh;
        margin-bottom: 5vh;
        width: 95%;
        padding-bottom: 2.5vh;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 100%;
        max-height: 100%;
    }

    #container_title {
        top: -7vh;
        font-size: 3vw;
    }


    .item_img {
        top: 0.2vh;
        width: 8vw;
        margin-bottom: 0.6vh;
    }

    .item_img_small {
        width: 7vw;
        margin-bottom: 0.6vh;
    }
}

@media only screen and (max-width: 500px) {


    #title {
        font-size: 10vw;
    }

    #xp_graph {
        left: 0vw;
        width: 95%;
        top: 1vh;
        height: 30vh;
        margin: 0 auto;
        margin-bottom: 6vh;
    }

    .chart_label_img {
        width: 6vw;
        height: 6vh;
    }

    #container {
        top: 0vh;
        margin: 0 auto;
        margin-top: 5vh;
        margin-bottom: 1vh;
        padding-bottom: 1vh;
        width: 100%;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; 
    }

    #container_title {
        top: -7vh;
        font-size: 4vw;
    }


    .item_img {
        top: 0.2vh;
        width: 10vw;
        margin-bottom: 0.6vh;
    }

    .item_img_small {
        width: 8vw;
        margin-bottom: 0.6vh;
    }

    .item_txt {
        font-size: 2.3vw;
    }


}