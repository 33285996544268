#welcome_body {
    position: relative;
    height: 100vh;
    margin: 0;
    text-align: center;
    overflow: hidden;
}

#clickDiv {
    position: absolute;
    top: 1vw;
    left: 1vw;
    z-index: 1200;
    display: flex;
    width: fit-content;
    animation: pulse infinite;
    animation-duration: 3s;
}

#clickMe {
    width: 1.5vw;
    height: 1.5vw;
    z-index: 1200;
    transform: scaleX(-1);
}

#clickP {
    position: relative;
    font-size: 1.6vh;
    top: -1vh;
    left: 0.2vw;
    color: var(--white);
    user-select: none;
}

#tsparticles {
    position: fixed;
    z-index: 100;
    overflow: hidden;
}


.welcome_txt {
    position: relative;
    opacity: 0.7;
    width: 55vw;
    top: 20vh;
    margin: 0 auto;
    font-size: 3.8vw;
    color: var(--white);
    letter-spacing: 6px;
    text-shadow: 4px 2px var(--lighter-black);
    animation: slideInLeft;
    animation-duration: 2s;
    user-select: none;
    z-index: 1200;
    font-weight: 200;
}

.welcome_txt_2 {
    animation: slideInRight;
    animation-duration: 2s;
    display: flex;
    justify-content: center;
}




#img {
    position: relative;
    z-index: 1000;
    border-radius: 5%;
    top: 25vh;
    width: 12vw;
    height: auto;
    animation: fadeInUp;
    animation-duration: 3s;
    z-index: 1200;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    object-fit: contain;
    user-select: none;
}


.img_div_text {
    position: relative;
    display: flex;
    justify-content: center;
    top: 26vh;
    width: 100%;
    height: 4vh;
    z-index: 1200;
}

.img_txt {
    font-size: 1.5vw;
    font-weight: 700;
    width: 20vw;
    margin: 0;
    color: var(--white);
    z-index: 1200;
    background-color: transparent;
    opacity: 0;
}

.theTag {
    z-index: 1200;
    margin: 0;
    width: 5%;
    color: var(--white);
    font-size: 3.8vw;
    font-weight: 100;
    opacity: 0;
}


#modal {
    transition: ease-in 1s;
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(160px);
    height: 100vh;
    border-radius: 50%;
    width: 100%;
    z-index: 1100;
    background-color: var(--grey);
    opacity: 0;
}



.welcome_txt:hover~#modal,
#img:hover~#modal,
#img:hover~.welcome_txt {
    transition: ease-in 1s;
    opacity: 1;
}

@media only screen and (max-width: 900px) {

    #clickMe {
        width: 3.5vw;
        height: 3.5vw;
    }

    #clickP {
        font-size: 1.2rem;
    }

    .welcome_txt {
        font-size: 5vw;
        width: 100%;
    }

    .theTag {
        font-size: 5vw;
    }

    #img {
        width: 28vw;
        top: 30vh;
    }

    .img_div_text {
        top: 32vh;
    }

    .img_txt {
        font-size: 2vw;
    }

}


@media only screen and (max-width: 500px) {

    #clickMe {
        width: 5.5vw;
        height: 5.5vw;
    }

    #clickP {
        font-size: 0.9rem;
    }

    .welcome_txt {
        font-size: 5vw;
        width: 100%;
    }

    .theTag {
        font-size: 5vw;
    }

    #img {
        width: 45vw;
        top: 25vh;
    }

    .img_div_text {
        top: 28vh;
    }

    .img_txt {
        font-size: 4vw;
    }

}