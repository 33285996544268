#work_body {
    margin: 10vh 2vw;
    padding-top: 2.5vh;
    background: var(--grey);
    z-index: 900;
    border-bottom: 1px solid rgb(80, 80, 80);
    border-top: 1px solid rgb(80, 80, 80);
    height: auto;
    position: relative;
}

#work_title {
    position: relative;
    font-size: 3rem;
    text-align: center;
    margin: 0;
    color: var(--white);
    user-select: none;
    text-shadow: 8px 2px var(--grey);
}

.work_row {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    padding: 1vh 1vw;
    justify-content: center;
    animation: fadeIn 3s;
}

.work_column {
    position: relative;
    width: 60vw;
    height: 100%;
    animation: fadeIn 3s;
    word-wrap: break-word;
    user-select: none;
}


.work_li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.work_logo {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin: 5px 10px;
}

.jppol_logo {
    width: 120px;
    object-fit: cover;
    margin: 5px 10px;
}

.work_code_no_ani {
    color: #ffa500;
    font-size: 0.8vw;
    white-space: nowrap;
    letter-spacing: .10em;
    display: inline-block;
    margin: 3px 10px;
}

.arrow {
    margin: 0vh 5px;
}

.ul_principles {
    margin: 0;
    padding: 0;
}




@media only screen and (max-width: 900px) {

    .work_code_no_ani {
        font-size: 1.5vw;
    }


}


@media only screen and (max-width: 500px) {
    #work_title {
        font-size: 10vw;
    }

    .work_column {
        margin: 2vh 0vw;
        width: 100%;
    }

    .work_li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        margin-bottom: 5vh;
    }

    .arrow {
        transform: rotate(90deg);
    }

    .work_code_no_ani {
        font-size: 3vw;
        overflow: auto;
    }

    .work_logo {
        width: 40px;
        height: 40px;
        object-fit: contain;
        margin: 5px 10px;
    }



}