@import url('https://fonts.googleapis.com/css2?family=Quantico:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

:root {
  --black: #09090a;
  --blue: #1c2c33;
  --green: #2c3434;
  --lighter-green: #3f4b4b;
  --grey: #111111;
  --ligther-grey: #1a1919;
  --ligther-lighter-grey: #202020;
  --lighter-black: #111113;
  --darker-blue: #080816;
  --white: rgb(255, 255, 255);
  --comp-to-blue: #161608;
  --font1: 'Quantico', sans-serif;
  --font2: 'Play', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--grey);
}

body::-webkit-scrollbar {
  width: 1em;
  background-color: transparent;

}

body::-webkit-scrollbar-thumb {
  background-color: var(--blue);
}

#root {
  margin: 0;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}