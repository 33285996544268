#projects_body{
    position: relative;
    z-index: 1000;
    margin: 2vw;
    padding: 10px;
    margin-bottom: 10vh;
    background-color: var();
}

.projects_title{
    text-align: center;
    user-select: none;
}


.projects_content{
    margin: 5vh 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: auto;
    flex-wrap: wrap;
}

.pro_col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1.5vw;
}

.a_project{
    max-width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4vh;
}


.projects_demo_p{
    margin: 0px 5px;
    text-align: center;
    border-radius: 5px;
    font-size: large;
    color: white;

}

.projects_demo_p:nth-child(1){
    color: orange;
    font-size: 1.4rem;
}

.project_demos_img{
    width: 370px;
    height: 100%;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.project_demos_img:hover{
    opacity: 0.95;
}


@media only screen and (max-width: 500px){

    .a_project{
        width: 300px;
    }

    .project_demos_img{
        width: 300px;
      
    }
}