.charts-css{
    --color-6: rgb(5, 71, 133) !important;
    --color-7: rgb(61, 5, 133) !important;
    --color-8: rgb(124, 7, 153) !important;
    --color-9: rgb(112, 4, 80) !important;
    --color-10: rgb(199, 8, 126) !important;
    --color-11: rgb(94, 78, 48) !important;
}

.charts-css.column.data-spacing-20 tbody tr{
    padding-inline-start: 12px !important;
    padding-inline-end: 12px !important; 
}

#chart {
    height: 100%;
    --secondary-axes-color: var(--ligther-grey);
}

#td_90 {
    --size: calc(90 / 100);
}

#td_80 {
    --size: calc(80 / 100);
}

#td_70 {
    --size: calc(70 / 100);
}

#td_60 {
    --size: calc(60 / 100);
}

#td_50 {
    --size: calc(50 / 100);
}

#td_40 {
    --size: calc(40 / 100);
}

#td_30 {
    --size: calc(30 / 100);
}