.chat_body{
    display: flex;
    flex-direction: column;
    align-items: center;
}


#chat{
    background-color: var(--ligther-grey);
    border-radius: 5px 5px 0 0;
    width: 50vw;
}

.messages_div{
height: 65vh;
   overflow-y: scroll;
}

.message{
    margin: 1vw 0 1vw 0.5vw;
    display: flex;
    align-items: baseline;
    flex-direction: row;
    text-align: left;
}

.username_chat{
    width: auto;
    margin: 0 1vw 0 0;
}

.user_message{
    margin: 0;
    word-break: break-all;
    width: 80%;
}

.message_stamp{
    color: rgb(94, 94, 94);
    font-size: 12px;
    margin-right: 5px;
    width: auto;
}



.input_div{
    position: sticky;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    background-color: var(--grey);
    width: 100%;
}

.input_chat{
    width: 100%;
    letter-spacing: 1px;
    font-weight: 600;
    background-color: var(--black);
    color: white;
    height: 3vh;
    font-family: var(--font1);
    border: none;
    padding: 5px;
}

.input_chat:focus{
    outline: none;
}

.chat_send_btn{
    font: inherit;
    background-color: black;
    color: white;
    border: none;
    width: 5vw;
}

div::-webkit-scrollbar {
    width: 1em;
  }
   
div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--main-darker-grey);
}
   
div::-webkit-scrollbar-thumb {
    background-color: black;
    outline: 1px solid black;
}

.please_login_div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;
}

.lobby_login_link{
    font-size: 22px;
    color: orange;
}
